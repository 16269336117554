<template>
  <div class="login">
    <!-- <div class="guding">
      <div>中国产学研合作促进会</div>
      <div>产学研合作创新与促进奖申报平台</div>
      <div class="guding_xia">
        <div>技术支持电话：</div>
        <div>18910278297</div>
      </div>
    </div>-->
    <div class="buttons">
      <div></div>
      <div class="buts">
        <!-- <div @click="open">查看详情</div> -->
        <div @click="open">奖项介绍</div>
        <div @click="opens">通知公告</div>
        <div @click="opense">帮助文档</div>
        <div @click="opee">声明</div>
      </div>
    </div>
    <div class="login_form">
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
      >
        <el-form-item prop="username">
          <el-input
            type="text"
            v-model="ruleForm.username"
            placeholder="账号"
          >
            <el-button slot="prepend" class="user">
              <img src="../assets/static/img/user.png" width="10" />
            </el-button>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            type="password"
            v-model="ruleForm.password"
            placeholder="密码"
          >
            <el-button slot="prepend" class="user">
              <img src="../assets/static/img/mima.png" width="10" />
            </el-button>
          </el-input>
        </el-form-item>
      </el-form>
      <div class="boxs">
        <div></div>
        <div class="mima" @click="$router.push({ path: '/forget' })">
          找回密码
        </div>
      </div>
      <!-- <div class="telse_header">
        <div style="margin-bottom: 10px">各单位管理员、申报者：</div>
        <div style="text-indent: 20px">
          奖项正在评审中，系统暂时停止登录，开放时间待评奖结束后另行通知。
        </div>
      </div> -->
      <!-- <div style="height: 10px"></div> -->
      <!-- <div class="ckb">
        <div>
          <input
            type="checkbox"
            class="checkbox"
            ref="check_inp"
            @change.stop="check"
            v-model="isShow"
          />记住密码
        </div>

      </div> -->
      <div class="btn" @click="btn">登录</div>
      <div class="foter" @click="enroll">
        <span>还没有账号？</span>
        <span style="color: #890510">去注册</span>
      </div>
    </div>
  </div>
</template>

<script>
// 手机号
export function elValidatePhone(rule, value, callback) {
  if (value == "") {
    return callback(new Error("请输入账号"));
  } else {
    return callback();
  }
}

// 手机号 密码
export function passPhone(rule, value, callback) {
  if (value == "") {
    return callback(new Error("密码不能为空"));
  } else {
    return callback();
  }
}
import { login } from "../axios/api.js";
export default {
  data() {
    return {
      ruleForm: {
        username: localStorage.getItem("username") || "",
        password: localStorage.getItem("password") || "",
      },
      isShow: localStorage.getItem("isShow") || false,
      rules: {
        username: [{ validator: elValidatePhone, trigger: "blur" }],
        password: [
          // { type: "", min: 6, message: "密码最低6位", trigger: "blur" },F
          { validator: passPhone, trigger: "blur" },
        ],
      },
    };
  },

  components: {},

  methods: {
    check() {
      console.log(this.isShow);
      if (this.isShow) {
        localStorage.setItem("username", this.ruleForm.username);
        localStorage.setItem("password", this.ruleForm.password);
        this.isShow = true;
        localStorage.setItem("isShow", this.isShow);
      } else {
        localStorage.removeItem("username");
        localStorage.removeItem("password");
        this.isShow = false;
        localStorage.removeItem("isShow");
      }
    },
    enroll() {
      this.$router.push({ path: "/enroll" });
    },
    open() {
      window.open(
        "https://ciur.kejie.org.cn/c23"
      );
    },
    opens() {
      window.open("https://ciur.kejie.org.cn/a980.html");
    },
    opense() {
      window.open("https://cjhwedang.oss-cn-beijing.aliyuncs.com/note/%E9%99%84%E4%BB%B65%EF%BC%9A%E8%AF%84%E5%A5%96%E7%B3%BB%E7%BB%9F%E6%93%8D%E4%BD%9C%E6%8C%87%E5%8D%97.pdf");
    },
    opee() {
      // window.open("http://" + window.location.host + "/5.pdf");
      window.open(
        "https://www.ciur.org.cn/a984.html"
      );
    },
    btn() {
      let params = {
        user_name: this.ruleForm.username,
        password: this.ruleForm.password,
      };

      login(params).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          sessionStorage.setItem("com_name", res.data.data.com_name);
          sessionStorage.setItem("Token", res.data.data.token);
          sessionStorage.setItem("user_name", res.data.data.user_name);
          sessionStorage.setItem("group_id", res.data.data.group_id);
          sessionStorage.setItem(
            "user_details_split_id",
            res.data.data.user_details_split_id
          );
          sessionStorage.setItem(
            "no_allow_resule",
            res.data.data.no_allow_resule
          );
          this.$router.push({ path: "/index" });
        } else {
          this.$message.error("登录失败");
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.login {
  width: 100%;
  height: 100%;
  background: url("../assets/static/img/background.jpg") no-repeat 100%;
  background-size: cover;
  overflow: hidden;
  .login_form {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    width: 500px;
    height: 450px;
    background-color: #fff;
    right: 15%;
    box-sizing: border-box;
    padding: 80px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .username {
      display: flex;
      align-items: center;
      width: 100%;
      height: 70px;
    }
    .userpws {
      display: flex;
      align-items: center;
      width: 100%;
      height: 70px;
    }

    .icon {
      width: 70px;
      height: 70px;
      border-right: 1px solid #999999;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .ckb {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .mima {
        color: red;
        cursor: pointer;

        font-size: 13px;
      }
    }
    .ckb > div {
      display: flex;
      align-items: center;
      color: #999;
      font-size: 13px;
    }
    .ckb > div:nth-child(1) {
      width: 60%;
      input {
        background: 1px solid #999;
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
    }
    .btn {
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 13px;
      background-color: #890510;
    }
    .foter {
      font-size: 13px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}
.bor {
  border: 1px solid red;
  box-shadow: 1px 0px 5px red;
}
input::placeholder {
  color: #999999;
}
.guding {
  position: absolute;
  right: 250px;
  top: 50%;
  transform: translateY(-50%);
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
  font-weight: 600;
}
.guding > div {
  font-size: 40px;
}
.guding_xia {
  display: flex;
  align-items: center;
}
.guding_xia > div:nth-child(1) {
  font-size: 30px;
}
.guding_xia > div:nth-child(2) {
  font-size: 25px;
}
.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  box-sizing: border-box;
  padding: 0 70px;
}
// .telse_header {
//   margin-top: -30px;
//   // height: 50px;
// }
.buts {
  display: flex;
}
.buts > div {
  width: 120px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #fff;
  color: #fff;
  cursor: pointer;
  margin: 0 10px;
}
.boxs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #890510;
  cursor: pointer;
}
</style>
